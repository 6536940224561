<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService" 
      :metadata="metadata"
      :details="details"
      translatePrefix="application.pages" />
  </div>
</template>

<script>
import { CargillCrudMetaView, helpers, responseToOptionsInContext } from '@cargill/shared'
import service from '../api/saleContractPremiumAndDiscountService'
import saleContractPremiumAndDiscountUSBucketAplicationService from '../api/saleContractPremiumAndDiscountUSBucketAplicationService'
import saleContractPremiumAndDiscountTypeAplicationService from '../api/saleContractPremiumAndDiscountTypeAplicationService'
import saleContractPremiumAndDiscountQualityAplicationService from '../api/saleContractPremiumAndDiscountQualityAplicationService'
import saleContractPremiumAndDiscountImpurityAplicationService from '../api/saleContractPremiumAndDiscountImpurityAplicationService'

      
export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getSaleContractPremiumAndDiscountUSBucketAplicationMeta() {
      const saleContractPremiumAndDiscountUSBucketAplicationMeta = await saleContractPremiumAndDiscountUSBucketAplicationService.getMetaWithValidation()
      helpers.processMetaDetails(
        saleContractPremiumAndDiscountUSBucketAplicationMeta,
        'saleContractPremiumAndDiscountUSBucketAplication',
        'saleContractPremiumAndDiscountId',
        'saleContractPremiumAndDiscount',
        saleContractPremiumAndDiscountUSBucketAplicationService
      )
      return saleContractPremiumAndDiscountUSBucketAplicationMeta;
    },
    async getSaleContractPremiumAndDiscountTypeAplicationMeta() {
      const saleContractPremiumAndDiscountTypeAplicationMeta = await saleContractPremiumAndDiscountTypeAplicationService.getMetaWithValidation()
      helpers.processMetaDetails(
        saleContractPremiumAndDiscountTypeAplicationMeta,
        'saleContractPremiumAndDiscountTypeAplication',
        'saleContractPremiumAndDiscountId',
        'saleContractPremiumAndDiscount',
        saleContractPremiumAndDiscountTypeAplicationService
      )
      return saleContractPremiumAndDiscountTypeAplicationMeta;
    },
    async getSaleContractPremiumAndDiscountQualityAplicationMeta() {
      const saleContractPremiumAndDiscountQualityAplicationMeta = await saleContractPremiumAndDiscountQualityAplicationService.getMetaWithValidation()
      helpers.processMetaDetails(
        saleContractPremiumAndDiscountQualityAplicationMeta,
        'saleContractPremiumAndDiscountQualityAplication',
        'saleContractPremiumAndDiscountId',
        'saleContractPremiumAndDiscount',
        saleContractPremiumAndDiscountQualityAplicationService
      )
      return saleContractPremiumAndDiscountQualityAplicationMeta;
    },
    async getSaleContractPremiumAndDiscountImpurityAplicationMeta() {
      const saleContractPremiumAndDiscountImpurityAplicationMeta = await saleContractPremiumAndDiscountImpurityAplicationService.getMetaWithValidation()
      helpers.processMetaDetails(
        saleContractPremiumAndDiscountImpurityAplicationMeta,
        'saleContractPremiumAndDiscountImpurityAplication',
        'saleContractPremiumAndDiscountId',
        'saleContractPremiumAndDiscount',
        saleContractPremiumAndDiscountImpurityAplicationService
      )
      return saleContractPremiumAndDiscountImpurityAplicationMeta;
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getSaleContractPremiumAndDiscountUSBucketAplicationMeta(),
        this.getSaleContractPremiumAndDiscountTypeAplicationMeta(),
        this.getSaleContractPremiumAndDiscountQualityAplicationMeta(),
        this.getSaleContractPremiumAndDiscountImpurityAplicationMeta(),
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      const ctx = await service.getValidationContext()
      responseToOptionsInContext(ctx)
      this.fieldsById = _.keyBy(meta.fields, (field) => field.id)
      this.fieldsById.premiumDiscountUnity.options = ctx.premiumDiscountUnityOptions
      this.generateValidationSchema()
      meta.details = metaDetails
      return meta
    },
    generateValidationSchema() {
      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        return yup.object().shape(validationSchema)
      }
    }
  },
  
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'app.pages')
      this.metadata = meta
    })
  }
}
</script>
